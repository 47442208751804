<template>
  <v-main style="margin-top: 60px;">
    <!-- <div :class="$route.name === 'MyMarketplaceTemplates' ? 'v-main__wrap' : 'v-main__wrap'" style="margin-top: 60px;"> -->
     <LandingPageSingle v-if="ActiveTemplatechild && PreviewMode" :ActiveTemplate="ActiveTemplatechild" :System="System" :SystemEntities="SystemEntities"
        :SitePages="SitePages" style="margin-top: 175px;" @TogglePreviewingMode="TogglePreviewingMode"
        />
        <!-- margin-top: 175px; only if not "STD Bar" -->
    <v-card v-if="!PreviewMode" class="transparent" flat tile>
         <!-- if userIsAdmin they can see Templates created by others, and edit them if need be...and they can see ra templates
        purpose of seeing ra templates from MyTemplatesView SOLEY to "download" from ra Templates to current suite templates
        And THIS TIME AROUND it actually installs with RA_Parentid on all children (if "site") and also "RA_Original_Templateid" for each "template doc" whether site doc, site children doc, or type "page"  -->
       <!-- Also one more note please, if the Template comes from RA...don't allow push basck to ra please?
       We now already use  RATemplateid so just be wise about it
       it means above note RA_Original_Templateid vs RATemplateid. consdier though...RATemplateid is for pushing suite to know it has been pushed. RA_Original_Templateid means this comes from RA.
       Very technically possibly when you take from RA marketplace, you should possibly never be allowed to push to ra again. unless you get different license agreement
       license agreement, if not overcomplicating now...essentially comes down to 1) license to use 2) license to resel...
       If I take a Template from somebody else, somethign they monetize on...how can I push it back as a Template I earn on?
       So by defualt NO plain NO may not...
       But that's probably not just true for RA_Original_Templateid prop it is probably also true for any template prop. shit. you get?
       I am on business-and-people I create a Template, push to marketplace I can earn. Bob comes, takes my Template, adds a few teaks, 
       sells it for half the freaking price then...and Bob steals my babies you know?
       So when I install a template...it must recorded that this is a template. and I may not push to marketplace if my "site" or "page" comes from a template...
       More so then, if RA_Original_Templateid applies, well same rule.
       Later, we can work around this by allowing someone to monetise by selling reselling rights. So R100 to use my template, R2000 if you want to be able to add tweaks and resell...
       You get?
       So perhaps then also it means I annot install from RA the way I allowed now...remember a suite admin is not ra admin. So you wanna allow a suite admin to "irate" Templates from RA, it means ra marketpalce 
       never menas anything ot anybody hwho sels on there, nobody would ever bother selling to ra marketplace because it allows piracy.
       So the "founder Template" right now, was initially intended for this platform. well all were anyway but...from this point on it's gotta be done right. Thus.
       I push to Ra marketplace, when isntalled to anotehr suite that suite doc gets RA_Original_Templateid prop which restricts pushing back to RA.
       It should technically in fact restrict...uhm
       shit!
       Okay yes this is how it is. 
       RA marketpalce only for ra suite...but IF ra template allows "reselling" or has 'reselling price" then that shows up in all suites...at cost of reslling price, even if that then be FREE...
       
         -->
         <v-card-text v-if="ActiveTemplate && ViewingTemplate && ActiveTemplate.Primary_Category && ActiveTemplate.Primary_Category.Name === 'Page'">
          <!-- <v-btn @click="DeactivateActiveTemplateComponent()">Close</v-btn>
          <v-btn @click="DeactivateActiveTemplateComponent(true)">Install</v-btn> -->
          <LandingPageSingle :ActiveTemplate="ActiveTemplate" :System="System" :SystemEntities="SystemEntities"
      />
        </v-card-text>
        <v-card-text v-if="ActiveTemplate && ViewingTemplate && ActiveTemplate.Primary_Category && ActiveTemplate.Primary_Category.Name === 'Site'">
          
          <v-card
              flat
              tile
            >
            <v-card-title v-if="ComputedTemplateChildren[SiteTemplateChildIndex]">
              <h1
                  style="font-size: 2rem;"
                  class="red--text"
                >
                  Page {{SiteTemplateChildIndex-1+2}} of {{ComputedTemplateChildren.length}} - {{ ComputedTemplateChildren[SiteTemplateChildIndex].Name }}
                </h1>
            </v-card-title>
              <v-window
                v-model="SiteTemplateChildIndex"
                
              >
                <v-window-item
                  v-for="card in ComputedTemplateChildren"
                  :key="card.itemObjKey"
                >
                  <v-card
                    color="grey"
                    height="600"

                  >
                   
                      
                       <LandingPageSingle class="background" :ActiveTemplate="card" :System="System" :SystemEntities="SystemEntities" style="z-index:1;overflow-y: scroll;height: 100%;"
                        />
                         <!-- <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                      style="position: absolute;background-color: rgba(38,38,38,0.3);width: 100%;top: 0;z-index:2;"
                    >
                   
                    </v-row> -->
                    <!-- <v-row
                      align="center"
                      justify="center"
                      style="position: absolute;background-color: rgba(38,38,38,0.3);width: 100%;top: 190px;z-index:2;height: 600px;"
                    >
                   
                    </v-row> -->
                    
                  </v-card>
                </v-window-item>
              </v-window>

              <v-card-actions class="justify-space-between">
                <v-btn
                  text
                  @click="prevSiteTemplateChildIndex"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-item-group
                  v-model="SiteTemplateChildIndex"
                  class="text-center"
                  mandatory
                >
                  <v-item
                   v-for="(card,cardindex) in ComputedTemplateChildren"
                  :key="card.itemObjKey"
                    v-slot="{ active, toggle }"
                  >
                    <v-btn
                      :input-value="active"
                      icon
                      @click="toggle"
                    >
                      <v-icon>mdi-record</v-icon>
                    </v-btn>
                    {{cardindex}}
                  </v-item>
                </v-item-group>
                <v-btn
                  text
                  @click="nextSiteTemplateChildIndex"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-card-text>
        
        <v-card-title v-if="ActiveTemplate && !ActiveTemplatechild">
            <!--  @click="CancelTemplateView()" -->
          <v-btn dark outlined color="error" @click="$route.name === 'MarketplaceTemplate' ? RoutetoAll() : CancelTemplateView()">
            <v-icon>mdi-keyboard-return
            </v-icon>
            All My Templates
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn  @click="ConfirmDeleteTemplate(ActiveTemplate)" dark outlined color="error">
                <v-icon>mdi-delete-forever
                </v-icon>Delete
            </v-btn>
            <v-btn v-if="RAUserObj && TransfertoRA && !ActiveTemplate.RATemplateid" @click="ConfirmTransfertoRA(ActiveTemplate)" dark outlined class="RAWebBtnGraddark">
                <v-icon>mdi-transfer
                </v-icon>Transfer to RA
            </v-btn>  
            <v-btn v-if="RAUserObj && TransfertoRA && ActiveTemplate.RATemplateid" disabled dark outlined class="RAWebBtnGraddark">
                <v-icon>mdi-transfer
                </v-icon>Already on RA
            </v-btn>          
        </v-card-title>
        <v-card-title>
            <!--  :to="'/MarketplaceTemplate/'+ActiveTemplate.id" -->
            <!-- <v-btn v-if="ActiveTemplate && !PreviewMode" @click="TogglePreviewingMode()" absolute top right dark outlined color="red" >
                {{ActiveTemplate.id}}
            </v-btn> -->
            <v-btn v-if="ActiveTemplate" @click="TogglePreviewingMode()" dark outlined color="blue" >
                Preview
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-menu			
                  :close-on-content-click="false"                            									
                  transition="scale-transition"                            									
                  >									
                  <template v-slot:activator="{ on }">						
                  <v-btn v-if="!ActiveTemplate" v-on="on" outlined>Add Template</v-btn>
                  </template>
                  <v-card tile>
                    <v-card-title class="black white--text">
                        Choose Below
                    </v-card-title>
                    <v-card-text>
                        <v-select return-object v-model="NewTempPrimCat" label="Primary Category" :items="PrimaryCategories" item-text="Name"/>
                        <v-select return-object v-model="NewTempSecCat" label="Secondary Category" :items="SecondaryCategories" item-text="Name"/>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn dark outlined color="warning">Cancel</v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn @click="ActivateMarketTemplateDialog()" dark outlined color="success">Process</v-btn>
                    </v-card-actions>
                  </v-card>  	
                </v-menu>
        </v-card-title>
        <v-card-text v-if="!ActiveTemplate">
            
          <v-row dense>
        <v-col
         v-for="temp in MarketplaceTemplatePackages" :key="temp.itemObjKey"
          :cols="TemplateCols"
        >
          <v-card flat tile width="300" class="mx-auto storecard transparent">
            <v-hover v-slot="{ hover }">
              <v-img class="item__overlayup black" height="200" contain :src="temp.Picture">
                <v-expand-transition>
                    <v-card tile
                        v-if="hover"
                        class="transition-fast-in-fast-out white--text"
                        style="height: 100%;background-color: rgba(40,50,60,0.7)"
                    >
                    <v-card-subtitle style="padding-bottom:0px;font-size: 1.2em;" class="white--text">
                            {{temp.Name}}
                        </v-card-subtitle>
                        <v-card-subtitle style="padding-top:10px;font-weight: bold;" class="white--text">
                            <v-avatar size="30" class="blue" >
                            <img v-if="userLoggedIn.Profile_Photo "
                            :src="userLoggedIn.Profile_Photo "    
                            style="object-fit: cover;"          
                            >
                            <img v-if="!userLoggedIn.Profile_Photo "
                                src="@/assets/BlankProfilePic.png"   
                                style="object-fit: cover;"           
                            >                      
                            </v-avatar>
                            {{temp.Owner.Full_Name}}
                        </v-card-subtitle>
                        <v-card-text class="white--text">
                        <div :id="temp.id+'smartdescription'">
                                <span  v-html="temp.Description ? smart_substr(temp.Description,200) : ''">
                                </span>
                            </div>  
                        </v-card-text>
                    </v-card>
                </v-expand-transition>
              </v-img>
            </v-hover>
            <v-card class="item__overlaydown" rounded elevation="8" :style="'background-image: radial-gradient(circle, rgba(252,70,107,0.1) 0%, rgba(63,94,251,0.1) 100%);'">
                <!-- <v-card class="item__overlaydown" rounded elevation="8" :style="'background-image: url('+require('@/assets/seamless_reed_basket_pattern.jpg')+';background-repeat: repeat;'"> -->
               <v-list dense class="transparent">
                <!-- only if FREE -->
                    <div v-if="temp.Price === 'Free'" style="position: absolute; right: 0px;top: -35px;">
                      <v-img height="60" contain :src="$vuetify.theme.dark ? require('@/assets/Gift_Box_Dark.png') : require('@/assets/Gift_Box_Light.png')"/>
                    </div>
                    <div class="title-ribbon">
                          <v-card-subtitle  class="mediumoverline white--text">
                             {{temp.Price === 'Free' ? temp.Price : CurrencyFormatter(temp.Price,$store.state.DefaultCurrency.Currency)}}
                        </v-card-subtitle>                      
                    </div>
                     <div style="position: absolute; right: 0px;top: 0px;">
                      <v-avatar size="70">
                      <v-img contain src="@/assets/logo.png"/>
                      </v-avatar>
                    </div>
                    </v-list>
                <v-card-actions class="justify-center" style="padding-top:60px;padding-bottom:15px;">
                    <v-btn color="RAWebBtnGraddark" dark v-if="RAUserObj && !temp.RATemplateid" @click="ActivateActiveTemplateComponent(temp,true)">
                        <span v-if="!MiniView">
                        Add to RA
                        </span>
                        <v-icon>
                            mdi-cart
                        </v-icon>
                        </v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn outlined dark color="blue" @click="ActivateActiveTemplateComponent(temp)">
                        <span v-if="!MiniView">
                        View
                        </span>
                    <v-icon v-if="MiniView">mdi-eye</v-icon>
                        </v-btn>  
                </v-card-actions>
            </v-card>
          </v-card>
        </v-col>
          </v-row>
        </v-card-text>
    </v-card>
    <v-layout class="justify-center background" v-if="!Refreshing && ActiveTemplate && !Editing && !PreviewMode">
    <div style="padding-top: 30px;padding-left:20px;padding-right:20px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11">
    <v-card elevation="0" class="siteconfigcard">
    <!-- <v-card v-if="!Refreshing && ActiveTemplate && !Editing" height="100%" width="100%"> -->
        <v-card-title>
           <v-text-field v-model="ActiveTemplate.Name" label="Name"/>
        </v-card-title>
        <v-card-subtitle>
            
        <ContentEditableField style="padding:15px;"
                :FieldObject="ActiveTemplate" :FieldName="'Description'"
                :FieldValue="ActiveTemplate.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
        </v-card-subtitle>
         <v-card-text>
            <v-text-field label="Price" v-model.number="ActiveTemplate.Price" />
            <v-select label="Publish Status" :items="['Draft','Published']" v-model="ActiveTemplate.PublishStatus" />
        </v-card-text>
       <v-card-text>									
                	 <!-- <input ref="SampleCoverIMGfile" type="file"  @change="NewSaveSampleCoverIMGselected($event)"/>								 -->
               <v-img class="black" height="200" contain v-if="!SampleCoverIMG && ActiveTemplate.Picture"									
                    :src="ActiveTemplate.Picture"									
                    >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <input id="SampleCoverIMGfile" type="file"  style="visibility: hidden;display: none;" @change="NewSaveSampleCoverIMGselected($event)"/>	
                    <label for="SampleCoverIMGfile">
                        <v-chip style="cursor: pointer;" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </label>
                    </v-row>
               </v-img>									
                    <v-img height="200" contain v-if="SampleCoverIMG"									
                    :src="SampleCoverIMG"									
                    >
                   <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <input id="SampleCoverIMGfile" type="file"  style="visibility: hidden;display: none;" @change="NewSaveSampleCoverIMGselected($event)"/>	
                    <label for="SampleCoverIMGfile">
                        <v-chip style="cursor: pointer;" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </label>
                    </v-row>
               </v-img>										
                    <v-img height="200" contain v-if="!ActiveTemplate.Picture && !SampleCoverIMG"									
                    src="@/assets/ImageHolder.png"									
                   >
                    <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
                    <input id="SampleCoverIMGfile" type="file"  style="visibility: hidden;display: none;" @change="NewSaveSampleCoverIMGselected($event)"/>	
                    <label for="SampleCoverIMGfile">
                        <v-chip style="cursor: pointer;" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
                        </label>
                    </v-row>
               </v-img>									
                <v-btn  outlined color="sucess" v-if="SampleCoverIMG" @click="SaveSampleCoverIMG()">									
                    Save Picture									
                </v-btn>									
            </v-card-text>	
         <v-btn @click="UpdateMarketTemplate()">									
                    Save									
                </v-btn>
                <!-- <v-switch v-model="Editing" label="Edit"/> -->
        <!-- <v-card-text v-if="ActiveTemplate.Components">
            <v-list>
                 <v-card-title>
                    Components
                 </v-card-title>
            <v-list-item @click="RoutetoTemplateComponentItem(item)" v-for="item in ActiveTemplate.Components" :key="item.itemObjKey">
                {{item.TemplateInfo.Component_Name}}
            </v-list-item>
            </v-list>
        </v-card-text> -->
    </v-card>    
    </div>
    <div style="padding-top: 30px;padding-left:20px;padding-right:20px;" class="align-content-center flex xl11 lg11 md11 sm11 xs11" v-if="ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Site'">
    <v-card elevation="0" class="siteconfigcard">
        <v-list v-if="!ActiveTemplatechild">
            <v-card-subtitle class="justify-end mediumoverline">
                Pages
                    <v-btn right absolute outlined @click="AddNewPage()" >Add Page <v-icon>mdi-plus</v-icon></v-btn>
            </v-card-subtitle>
            <!-- <v-list-item class="justify-end">
             <v-btn right outlined @click="AddNewPage()" >Add Page <v-icon>mdi-plus</v-icon></v-btn>
            </v-list-item> -->
            <v-list-item @click="ActivateTemplateChild(child)" class="detailslistoutline" v-for="child in ComputedTemplateChildren" :key="child.itemObjKey">
                {{child.Name}}
            </v-list-item>
        </v-list>
    </v-card>
    </div>
    </v-layout>
    <v-card class="transparent" flat tile width="100%" height="100%" v-if="!Refreshing && Editing && !PreviewMode" >
        <v-card-subtitle class="mediumoverline">
            {{ActiveTemplate.Name}}
        </v-card-subtitle>
         <v-card-title>
          <v-btn outlined @click="Refresh()">Back
            </v-btn>
            <v-spacer>
            </v-spacer>
           <!-- <v-chip @click="AddNewPage()" >Add Page <v-icon>mdi-plus</v-icon></v-chip> -->
        </v-card-title>
       <!-- <v-card-text v-if="ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Site' && ActiveTemplatechild"> -->
        
        <!-- <v-btn @click="ViewingTemplate = !ViewingTemplate">ViewingTemplate</v-btn> -->
         
    </v-card>
    <NewLandingPage :ActiveTemplate="ActiveTemplatechild" :System="System" :SystemEntities="SystemEntities" style="margin-top: -100px;"
                :SitePages="SitePages"v-if="ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Site' && ActiveTemplatechild && !Refreshing && Editing && !PreviewMode"
                />
       <!-- </v-card-text> -->
                <NewLandingPage :ActiveTemplate="ActiveTemplate" :System="System" :SystemEntities="SystemEntities" style="margin-top: -100px;"
                :SitePages="SitePages" v-if="ActiveTemplate && ActiveTemplate.Primary_Category.Name === 'Page' && !Refreshing && Editing && !PreviewMode"
                />
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import NewLandingPage from '@/components/WebPages/NewLandingPage';
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';	
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'	
export default {
    props: ['System','SystemEntities','SitePages','RADB'],
    components: {NewLandingPage,LandingPageSingle,ContentEditableField},
    data() {
        return {
            ViewingTemplate: false,
            NewTempPrimCat: '',
            NewTempSecCat: '',
            PrimaryCategories: [
                {ID:1000001,Name: 'Site'},
                {ID:1000002,Name: 'Page'}
            ],
            SecondaryCategories: [
                {ID: 1000001, Name: 'Website'},
                {ID: 1000002, Name: 'Social Network'},
                {ID: 1000003, Name: 'Group Site'},
                {ID: 1000004, Name: 'Featured Member'}
            ],
            TransfertoRA: false,
            Editing: false,
            NewCoverIMG: '',
            SampleCoverIMG: '',
            Refreshing: false,
            ActiveTemplate: '',
            ActiveTemplatechild: '',
            PreviewingMode: false
        }
    },	
    computed:{
        ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
      },
        SiteTemplate(){
            return this.ActiveMarketTemplate && this.ActiveMarketTemplate.Primary_Category.Name === 'Site'
        },
        WebsiteTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Website'
        },
        SocialNetworkTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Social Network'
        },
        FeaturedMemberTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Featured Member'
        },
        GroupSiteTemplate(){
            return this.SiteTemplate &&
            this.ActiveMarketTemplate.Secondary_Category && this.ActiveMarketTemplate.Secondary_Category.Name === 'Group Site'
        },
        PreviewMode(){
            return !this.UserCanEdit || this.UserCanEdit && this.PreviewingMode
        },
        UserCanEdit(){
            return this.ActiveTemplate && this.ActiveTemplate.Ownerid === this.userLoggedIn.id || 
            this.$route.name === 'MyMarketplaceTemplates'
            //for support must add  || this.userIsAdmin once past testing
        },
        BuilderView(){
      return this.$store.state.BuilderView
      },
      NetworkBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
       UserBillingAccounts(){
      return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : []
    },
        AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : ''
        },
        RAAdmin(){
        return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
    },
    RAUserObj(){
        return this.$store.state.RAUserObj
    },
        TemplateCols(){
            let cols = 12
            if(this.$vuetify.breakpoint.xl){
                cols = 2
            }
            else if(this.$vuetify.breakpoint.lg){
                cols = 3
            }
            else if(this.$vuetify.breakpoint.md){
                cols = 4
            }
            else if(this.$vuetify.breakpoint.sm){
                cols = 6
            }
            return cols
        },
        MyTemplatesView(){
            return this.$route.name === 'MyMarketplaceTemplates'
        },
        ComputedTemplateChildren(){
            return this.ActiveTemplate && this.ActiveTemplate.Children ? 
            this.MarketplaceTemplates.filter(temp => {
                return this.ActiveTemplate.Children.includes(temp.id)
            }) :
            []
        },
        MarketplaceTemplatePackages(){
            return this.MyMarketplaceTemplates.filter(temp => {
                return !temp.Parentid
            }).map(temp => {
                let temppbj = Object.assign({},temp)
                if(typeof temppbj.Price === 'undefined'){
                    temppbj.Price = 'Free'
                }
                return temppbj
            })
        },
        MarketplaceTemplates(){
        return this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates)
        },
        OtherMarketplaceTemplates(){
        return this.$store.state.OtherMarketplaceTemplates
        },
        MyMarketplaceTemplates(){
        return this.$store.state.MyMarketplaceTemplates
        },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ActiveFeaturedMember(){
            return this.$store.state.ActiveFeaturedMember
        },
        NewSitePageid(){
        return this.$store.state.NewSitePageid
      },
    },
    watch: {
        ActiveTemplate: {
            handler: function(newvalue, oldvalue) {
             console.log(oldvalue, newvalue,'this.$store.state',this.$store.state)
                if(newvalue !== oldvalue){   
                    if(!newvalue.id && this.BuilderView){
                        this.$store.commit('setBuilderView','')
                    }   
                    if(newvalue.id){
                        this.$store.commit('setActiveMarketTemplate',newvalue)
                        if(newvalue.Primary_Category && newvalue.Primary_Category.Name === 'Site'){
                            this.$store.commit('setActiveTemplateSite',newvalue)
                            this.SetBuilderView()
                        }
                    }         
                    
                    if(newvalue && newvalue.Secondary_Category && newvalue.Secondary_Category.Name === 'Featured Member'){
                        //okay it's a fet member do we have one active?
                        if(!this.ActiveFeaturedMember){
                            //we need to set active member how do we do that?
                            let query = db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('featuredmembers').doc(this.userLoggedIn.id)
                            query.onSnapshot(snapshot => {
                                let FeaturedMember = snapshot.data()
                                FeaturedMember.id = this.userLoggedIn.id
                                FeaturedMember.Query = query
                                console.log('this.FeaturedMember ',FeaturedMember)
                                this.$store.dispatch('GetActiveFeaturedMember',FeaturedMember) 
                                console.log(this.$store.state.ActiveFeaturedMember)
                            })
                        }
                    }
                }
            },deep: true
        },
        // ActiveTemplatechild: {
        //     handler: function(newvalue, oldvalue) {
        //         if(newvalue !== oldvalue){
        //             if(newvalue.id){
        //                 this.$store.commit('setActiveMarketTemplate',newvalue)
        //                 console.log(this.$store.state.ActiveMarketTemplate)
        //             }   
        //         }
        //     },deep: true
        //decided not to because now the ActiveMarketTemplate is parent and could receive nabar items etc!
        // },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['PerformSitePageAddition','RoutetoTemplatePage']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        if(!this.MyTemplatesView){
       this.GetMarketplacetemplate()
        }
    },
    methods:{
        RoutetoAll(){
            this.$router.push('/MyMarketplaceTemplates')
            this.CancelTemplateView()
            this.$store.commit('setActiveTemplateSite','')
        },
        RoutetoTemplatePage(page){
            let child = this.ComputedTemplateChildren.find(obj => obj.id === page.id)
            this.ActiveTemplatechild = child
            //console.log(this.ActiveTemplatechild,this.UserCanEdit,this.PreviewingMode,this.PreviewMode,page,this.ComputedTemplateChildren)
        },
        TogglePublicNavbarEditing(){
            this.$emit('TogglePublicNavbarEditing')
        },
        TogglePreviewingMode(){
            this.PreviewingMode = !this.PreviewingMode
            if(this.PreviewingMode){
                this.$store.commit('setBuilderView','')
                this.TogglePublicNavbarEditing()
                //okay here is another thing. not 1. Check if type, like if website template check ActiveMarketTemplate.TemplateProps.Public_Landing_Page. Get matching form computed children
                //2. If found use that, otherwise child[0] will do
                let defpageprop = ''
                let defpage = ''
                let defpageroute = ''
                let pagechild = ''
                if(this.WebsiteTemplate){
                    defpageprop = 'Public_Landing_Page'
                }
                else if(this.SocialNetworkTemplate){
                    defpageprop = 'Social_Landing_Page'
                }
                //console.log(defpageprop,this.ActiveMarketTemplate,this.ActiveMarketTemplate.TemplateProps[defpageprop])
                if(defpageprop && this.ActiveMarketTemplate.TemplateProps[defpageprop]){
                    defpageroute = this.ActiveMarketTemplate.TemplateProps[defpageprop].split('/').join('').split('-').join(' ').split('_').join(' ')
                    pagechild = this.ComputedTemplateChildren.find(obj => obj.TemplateObj.Name === defpageroute)
                    if(pagechild){
                        defpage = pagechild.TemplateObj
                    }
                }
                if(defpage){
                    this.ActivateTemplateChild(pagechild)
                    this.ActiveTemplatechild = pagechild
                }
                else if(this.ComputedTemplateChildren[0]){
                    this.ActivateTemplateChild(this.ComputedTemplateChildren[0])
                }
                //still need to set others, think group has def root, feat mem not, because it's "route.id" AND "slug"...
            }
            else{
                this.SetBuilderView()
                this.TogglePublicNavbarEditing()
                //this.DeactivateTemplateChild()
            }
        },
        PerformSitePageAddition(newpage){
            console.log(newpage,this.NewSitePageid)
            let children = []
            if(this.ActiveTemplate.Children){
                children = this.ActiveTemplate.Children
            }
            children.push(this.NewSitePageid)
            db.collection('marketplacetemplates').doc(this.ActiveTemplate.id).update({
                Children: children
            }).then(updatedparetn => {
                this.$store.commit('setNewPageSite','')
                this.$store.commit('setNewSitePageid','')
                let acttemp = this.ActiveTemplate
                this.ActiveTemplate = ''
                setTimeout(() => {
                    this.ActiveTemplate = acttemp
                }, 50);
            })
        },
        AssignSocialItemInteractMethod(item,SecondProp){
        //console.log('AssignSocialItemInteractMethod',item,SecondProp)
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','')
        if(SecondProp){
            this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        else if(item){
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        },
        AddNewPage(navbar,SubPage,parentindex){
            //console.log(navbar,SubPage,parentindex)
          let type = 'Public'
          if(this.ActiveTemplate.Secondary_Category.Name === 'Social Network'){
            type = 'Members Only'
          }
          this.$store.commit('setNewPageSite',this.ActiveTemplate)
          this.AssignSocialItemInteractMethod('ToggleNewPageDialog',type)
        },
        ActivateTemplateDialog(primcat,seccat){
            console.log(primcat,seccat)
            //the main issue being the route params assigned here, that's it
            //that equates to
            //1.RouteName WTf it means don't even care don't think it matters
            //2. the "array" values below meaning jack shit, if social network, but yes applicable if website or feat mem
            //3.Templateid like ???
            //4. Components for site will be empty
            //was passing primcat,index
            //ComputedSitePages
            //console.log('index',index,this.ComputedNavbarPages[index])
            let ref = db.collection('marketplacetemplates').doc()
            let Templateid = ref.id
            let user = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
            let date = new Date()
            let systemid = process.env.VUE_APP_RA_SYSTEM_ID
            let systemurl = this.System.ClientAppURL
            let defobj = {
                Templateid: '',
                Systemid: systemid,
                ClientAppURL: systemurl,
                PublishStatus: 'Draft',
                Owner: user,          
                Ownerid: user.id,
                RouteName: this.$route.name,
                Created_By: user,          
                Created_Byid: user.id,
                Modified_By: user,          
                Modified_Byid: user.id,
                Created_On: date,
                Modified_On: date,
                // TemplateProps: {},
                TemplateObj: {},
                Primary_Category: primcat,
                Secondary_Category: seccat
            } 
            let payload = Object.assign({},defobj)
            payload.Primary_Category = primcat
            if(primcat.Name === 'Site'){
                payload.Templateid = Templateid
                //yes because slug is the page
                payload.TemplateProps = {
                    //Children: this.ComputedNavbarPages,
                    //ComputedSitePages but yeah gonna need simply template ids right?
                     //[this.SelectedNavbar.Prop]: this.NavListArray
                     //should cover for all I guess?
                     Navlist: [],
                     NavlistProp: ''
                }
                let array = ['PublicNavbarDark','PublicNavbarStyle','PublicNavbarText','PublicNavbarSelectedText','PrependedPublicNavbarActions','AppendedPublicNavbarActions','Public_NavBar_IMG','PublicNavbarItemSelectBG','PublicNavbarItemHoverBG']
                if(seccat.Name === 'Featured Member'){
                    payload.TemplateProps.NavlistProp = 'MenuItems'
                }
                else if(seccat.Name === 'Website'){
                    payload.TemplateProps.NavlistProp = 'PublicNavbarItems'
                }
                else if(seccat.Name === 'Social Network'){
                    payload.TemplateProps.NavlistProp = 'SocialNavbarItems'
                    array = []
                    //??????
                }
                //IRRELEVANT THERE IS NO SITE!!!

                // array.map(prp => {
                //     if(typeof this.Site[prp] !== 'undefined'){
                //         payload.TemplateProps[prp] = this.Site[prp]
                //     }
                // })
                // payload.Components = this.ComputedNavbarPages.map(page => {
                //     let childtempobj = Object.assign({},defobj)
                //     let childpage = this.ComputedSitePages.find(obj => obj.Name === page.Name)
                //     if(childpage){
                //         childtempobj.Templateid = childpage.id
                //     }
                //     else{
                //         childtempobj.Templateid = page.Name.split(' ').join('_')
                //     }
                //     childtempobj.TemplateObj = page
                //     childtempobj.Icon = page.PageIcon
                //     childtempobj.Name = page.Name
                //     return childtempobj
                // })
                //?
            }
            else{
                Templateid = Templateid
                //yes because id is the member id
                let temppage = this.ComputedNavbarPages[index]
                TemplateObj = {
                    Icon: temppage.PageIcon,
                    //how do we get entire page on here?
                    ...temppage
                }
            }
            this.$store.commit('SetSocialItemInteractMethodProp',payload) 
            this.$store.commit('SetSocialItemInteractMethod','ActivateMarketplaceTemplateDialog')
        },
        ActivateMarketTemplateDialog(){
            this.ActivateTemplateDialog(this.NewTempPrimCat,this.NewTempSecCat)
            this.NewTempPrimCat = ''
            this.NewTempSecCat = ''
        },
        ChangeSampleCoverIMGselected(){
            this.$refs('SampleCoverIMGfile')
        },
        smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },	
        // This should be in AppNotifications
        //comes from C:\RANEWApps\DNetGmailAccount\businessandpeople\src\components\SuitePlugins\Blogs\Single.vue
        ActivateTokenPurchaseDialog(){     
        let vm = this
         if(!this.UserMarketAccount && this.AppMonetizeBU){
           vm.$store.commit('setCustomProcessingDialogText','Creating Market Account')
           vm.$store.commit('setCustomProcessingDialog',true)
           const functions = firebase.functions();
           const CreateMarketAccount = functions.httpsCallable('CreateMarketAccount');
           let payload = {
             Data: vm.userLoggedIn,
             AppMonetizeBU: vm.AppMonetizeBU,
                    siteid: vm.$store.state.ActiveSuiteid
           }
           CreateMarketAccount(payload).then(result => {
             console.log(result)
             vm.$store.commit('setCustomProcessingDialogText','Done Creating')
             vm.ProcesstokenPurchase()
           })
         }
         else{
           vm.ProcesstokenPurchase()
         }
        console.log('definitley going to pass app notification push token packages etc. refer EmitMarketDialog()')
        let transaction = {
          Business_Unit: this.AppMonetizeBU,
          Business_Unitid: this.AppMonetizeBU.id,
          ROE: Number(this.System.Credit_Tokens_ROE),
          Date: new Date(),
          Type: {ID: 1000001, Name: 'User Token Purchase'},
          
        }
        },
         // This should be in AppNotifications
        CancelTemplateView(){
            this.ActiveTemplate = ''
            this.ActiveTemplatechild = ''
            this.Editing = false
            this.TransfertoRA = false
        },
        ConfirmTransfertoRA(ActiveTemplate){
            confirm('Do you want to place this Template into the RapidApps platform marketplace?') && this.ProcessTransfertoRA(ActiveTemplate)
        },
        ProcessTransfertoRA(ActiveTemplate){
             let path = this.RADB.collection('marketplacetemplates')
                let parentref = path.doc()
            let ratemplate = Object.assign({},ActiveTemplate)
            ratemplate.id = parentref.id
             let clientuser = {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id}
            let user = {Full_Name: this.RAUserObj.Full_Name,id: this.RAUserObj.id}
            let date = new Date()
             let children = this.MarketplaceTemplates.filter(temp => {
                    return ActiveTemplate.Children.includes(temp.id)
                }).map(child => {
                    let childref = path.doc()
                    let childobj = Object.assign({},child)
                    childobj.ClientSuiteTemplateid = childobj.id
                    childobj.ClientOwner = clientuser
                    childobj.ClientOwnerid = clientuser.id
                    childobj.Owner = user          
                    childobj.Ownerid = user.id
                    childobj.Created_By = user     
                    childobj.Created_Byid = user.id
                    childobj.Modified_By = user         
                    childobj.Modified_Byid = user.id
                    childobj.Created_On = date
                    childobj.Modified_On = date
                    childobj.id = childref.id
                    childobj.Original_Parentid = childobj.Parentid
                    childobj.Parentid = ratemplate.id
                    return childobj
                })
            let total = children.length
            let running = 0
            ratemplate.ClientSuiteTemplateid = ratemplate.id
            ratemplate.ClientOwner = clientuser
            ratemplate.ClientOwnerid = clientuser.id
            ratemplate.Owner = user          
            ratemplate.Ownerid = user.id
            ratemplate.Created_By = user     
            ratemplate.Created_Byid = user.id
            ratemplate.Modified_By = user         
            ratemplate.Modified_Byid = user.id
            ratemplate.Created_On = date
            ratemplate.Modified_On = date
            ratemplate.Original_Children = ratemplate.Children
            ratemplate.Children = children.map(child => {
                return child.id
            })
            if(children.length > 0){
                children.map(child => {
                path.doc(child.id).set(child).then(childnew => {
                        running++
                        if(running === total){
                            path.doc(ratemplate.id).set(ratemplate).then(parentnew => {
                                db.collection('marketplacetemplates').doc(this.ActiveTemplate.id).update({
                                    RATemplateid: ratemplate.id
                                }).then(updateddoc => {
                                  this.CancelTemplateView()  
                                })                                
                            })
                        }
                    })
                })
            }
            else{
                path.doc(ratemplate.id).set(ratemplate).then(parentnew => {
                    db.collection('marketplacetemplates').doc(this.ActiveTemplate.id).update({
                        RATemplateid: ratemplate.id
                    }).then(updateddoc => {
                        this.CancelTemplateView()  
                    })
                })
            }

        },
        ConfirmDeleteTemplate(ActiveTemplate){
            confirm('This will delete the Template forever and there is no undo, are you sure?') && this.ProcessDeleteTemplate(ActiveTemplate)
        },
        ProcessDeleteTemplate(ActiveTemplate){
            if(!ActiveTemplate.Children){
            db.collection('marketplacetemplates').doc(ActiveTemplate.id).delete().then(deldoc => {
                this.CancelTemplateView()
            })
            
            }
            else{
                let children = this.MarketplaceTemplates.filter(temp => {
                    return ActiveTemplate.Children.includes(temp.id)
                })
                let total = children.length
                let running = 0
                children.map(child => {
                    db.collection('marketplacetemplates').doc(child.id).delete().then(childdel => {
                        running++
                        if(running === total){
                            db.collection('marketplacetemplates').doc(ActiveTemplate.id).delete().then(deldoc => {
                                this.CancelTemplateView()
                            })
                        }
                    })                    
                })
            }
        },
        SetBuilderView(){
            if(this.UserCanEdit){
                //for support must add  || this.userIsAdmin once past testing
                this.$store.commit('setBuilderView',{Name: 'Marketplace Site'})
            }                
        },
        ActivateActiveTemplateComponent(temp,toRA){
        this.ActiveTemplate = temp
        this.TransfertoRA = toRA
        if(this.$route.name === 'MyMarketplaceTemplates'){
            if(this.ActiveTemplate && this.ActiveTemplate.Primary_Category && this.ActiveTemplate.Primary_Category.Name === 'Site'){
                this.SetBuilderView()
                
            }
            else if(this.BuilderView){
                this.$store.commit('setBuilderView','')
            }
        }
      },
        SaveSampleCoverIMG(){									
            if(this.NewCoverIMG){		
                this.$emit('ActivateProcessing',true)								
                let vm = this                									
                var storageRef = 'UserAssets/'+vm.ActiveTemplate.Ownerid+'/Assets/'+new Date()+'/'+vm.NewCoverIMG.name									
                vm.PrepareSampleCoverThumbnail(vm.NewCoverIMG.name,vm.NewCoverIMG,storageRef)									
                									
            }									
        },
        PrepareSampleCoverThumbnail(filename,file,storageref){									
        let vm = this									
        return new Promise(function(resolve, reject) {									
        file.tmpsrc = URL.createObjectURL(file)									
              //this.OutboundPhotosforUpload.push(file)									
              var img = document.createElement("IMG");									
                img.setAttribute('width',150)									
                img.setAttribute('src',file.tmpsrc)									
                document.body.appendChild(img)									
                									
                img.onload = function() {									
                var c = document.createElement("canvas");									
                var ctx = c.getContext("2d");									
                var canvasRatio = img.naturalHeight / img.naturalWidth									
                c.width = 400									
                c.height = c.width*canvasRatio									
                ctx.drawImage(img, 0, 0, c.width, c.height);									
                c.toBlob(blob => {									
                  let blobfile = new File([blob], filename, {									
              type: file.type,									
            });									
            //console.log(blobfile)									
            var storageRef = firebase.storage().ref(storageref+'_thumb')									
                document.body.removeChild(img)									
                var uploadTask = storageRef.put(blobfile)									
                uploadTask									
                  .then(snapshot => snapshot.ref.getDownloadURL())									
                    .then((thumburl) => {									
                      //console.log(thumburl)	
                      vm.$emit('ActivateProcessing',false)									
                      vm.ActiveTemplate.Picture = thumburl	
                      vm.SampleCoverIMG = ''
                      vm.NewCoverIMG = ''								
                      //vm.CancelEditTestimony()									
                    })									
              })									
                }									
        })									
      },	
        NewSaveSampleCoverIMGselected(event){								
            this.NewCoverIMG = event.target.files[0]
            console.log('this.NewCoverIMG',this.NewCoverIMG)									
            this.SampleCoverIMG = URL.createObjectURL(this.NewCoverIMG)									
        },		
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
            //console.log(AdditionalSaveMethod)
            if(AdditionalSaveMethod){
                this[AdditionalSaveMethod]()
            }
        },
        UpdateMarketTemplate(){
            let updateobj = {
                PublishStatus: this.ActiveTemplate.PublishStatus,
                Name: this.ActiveTemplate.Name,
                Description: this.ActiveTemplate.Description,
            }
            if(this.ActiveTemplate.Picture){
                updateobj.Picture = this.ActiveTemplate.Picture
            }
            if(this.ActiveTemplate.Price){
                updateobj.Price = this.ActiveTemplate.Price
            }
            db.collection('marketplacetemplates').doc(this.ActiveTemplate.id).update(updateobj).then(updateddoc => {
                if(this.ComputedTemplateChildren.length > 0){
                    this.ComputedTemplateChildren.map(childtemp => {
                        db.collection('marketplacetemplates').doc(childtemp.id).update({
                            PublishStatus: this.ActiveTemplate.PublishStatus,
                        })
                    })
                }
            })
            //and really some snackbar inbetween and shit
        },
        ActivateTemplateChild(child){
            this.Editing = true
            this.ActiveTemplatechild = child
            //okay so if Guest, then activate the Builderappbarview...
        },
        DeactivateTemplateChild(){
            this.ActiveTemplatechild = ''
        },
        Refresh(){
            this.Refreshing = true
            if(this.ActiveTemplatechild){
                this.ActiveTemplatechild = ''
                this.Editing = false
            }
            else{
                this.Editing = false
            }
            setTimeout(() => {
                this.Refreshing = false
            }, 300);
        },
        // RoutetoTemplateComponentItem(item){
        //     //this is going to be hella difficult!!!!
        //     //essentially now I need to edit the item "as is"
        //     //with the first case being featuredmembereditot, this is what I need to do...
        //     //challenge being...there is no route I can use
        //     //if I used FeaturedMember Editor it won't work because the item won't exist, cannot be called on, cannot push an entire page
        //     //additionally, this route is now unique, what route would I even use right?
        //     //Do I need to get a "page preview" route now? And now what happens with things like data table, would I get sample routes for that as well?
        //     //did we even think this through?
        //     //No of course we clearly did not think this through. 
        //     //nothing is going to render unless we undertake a MAMMOTH exercise to get everything "preview ready"
        //     //Relax...what if we used landingpagesingle? or Newlandingpage?
        //     this.ActiveTemplateComponent = item
        // },
        GetMarketplacetemplate(){
            let template = this.MarketplaceTemplates.find(obj => obj.id === this.$route.params.id)
            //console.log('template',template,this.MarketplaceTemplates,this.$store.state)
            if(!template){
                 if(!this.userLoggedIn){
                this.$router.push('/404')  
                }
                else{
                this.$router.push('/404')
                } 
            }
            else{
                //undid if(template.Ownerid !== this.userLoggedIn.id && !this.userIsAdmin){ as admin also get to view preview mode first right
                if(template.Ownerid !== this.userLoggedIn.id){
                    //this.$router.push('/PermissionError')
                    this.ActiveTemplate = template
                    //I know this is not right but give me a second
                }
                else{
                 this.ActiveTemplate = template   
                 if(this.ActiveTemplate && this.ActiveTemplate.Primary_Category && this.ActiveTemplate.Primary_Category.Name === 'Site'){
                    this.SetBuilderView()
                    this.$store.commit('setActiveTemplateSite',template)                    
                }
                }
                
            }
        }
    }
}
</script>

<style>
</style>



